.box-doc {
  border: 1px $secondary solid;
  border-radius: 1em;
  padding: 1em;
  box-shadow: 0.1rem 0.25rem 0.25rem 0.1em rgb(0 0 0 / 20%);

  .data {
    border-radius: 10px;
    background-color: $secondary;
    color: $white;
    text-align: center;
    padding: 0.4em;
    box-shadow: 0.1rem 0.25rem 0.25rem 0.1em rgb(0 0 0 / 10%);

    @include media-breakpoint-up(md) {
      height: auto;
      aspect-ratio: 1/1;
    }

    .g-settimana {
      font-size: 0.7rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    .g-mese {
      font-size: 2rem;
      line-height: 100%;
    }

    .mese-anno {
      font-size: 1rem;
      text-transform: uppercase;
    }
  }

  .informazioni {
    line-height: 1.7rem;

    h2 {
      color: #2d2d2d;
      font-size: 1.2rem;
      font-weight: 600;
    }

    span {
      font-weight: 300;
      font-size: 0.8rem;
    }
  }

  .informazioni-ricetta {
    h2 {
      font-size: 1rem;
      font-weight: 400;
      text-transform: uppercase;
      color: $secondary;
      margin-bottom: 0.15rem;
    }

    h3 {
      color: #2d2d2d;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      font-weight: 300;

      .nre {
        font-weight: 500;
      }
    }
  }
}

@include media-breakpoint-down(xl);
