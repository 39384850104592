@use "sass:color";
@import "./base/base";
@import "./components/components";
@import "./utilities/icons";

.animate-rotate {
  animation: rotation 20s infinite linear;
}

.animate1 {
  animation: up-down 1.5s infinite alternate;
}
@media only screen and (max-width: 1380px) {
  .animate1 {
    width: 5rem;
  }
}

.breadcrumb {
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);

  a {
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
      color: color.adjust($primary, $lightness: -30%);
      text-decoration-color: color.adjust($primary, $lightness: -30%);
    }
  }

  .xng-breadcrumb-item,
  .xng-breadcrumb-trail {
    color: $primary;
  }
}

.w-lg-25 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 25%;
  }
}

.w-md-auto {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.main-page {
  background-image: url("../assets/bg1.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
  background-color: #f7f7ff;

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    background-image: url("../assets/shape1.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 100%;
    z-index: -1;
  }
}

.main-content {
  background-color: $white;
  border-radius: 1.5rem;
  box-shadow: 0 0 2.5rem $gray-400;
  padding: 1.5rem 0;

  @include media-breakpoint-up(lg) {
    padding: 2.5rem;
  }
}

.inline-app-loader {
  max-height: 1rem;
  scale: 0.5;
}

.anteprima-pdf .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 1320px;

  .modal-content {
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 992px) {
  .anteprima-pdf .modal-dialog {
    padding: 24px;
  }
}
