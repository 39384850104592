ngb-pagination {
  .page-item {
    .page-link {
      color: $secondary;
    }

    &.active {
      .page-link {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }

    &.disabled {
      .page-link {
        color: #868e96;
      }
    }
  }
}
