@import "~bootstrap/scss/mixins";

a {
  // color       : $td;
  text-decoration: none;
  outline: none;
  color: $primary;
}

html {
  font-size: 16px;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

body {
  font-family: $font-family-base;
  font-size: 1rem;
  color: $gray-700;
  font-weight: 400;

  &.fixed {
    overflow: hidden;
    position: fixed;
  }
}

// Heading
h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: 600;
  @include media-breakpoint-down(xxl) {
    font-size: 1.86em;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.69em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.55em;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;
  font-weight: 600;
  @include media-breakpoint-down(xxl) {
    font-size: 1.7em;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.37em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.26;
  }
}

h3,
.h3 {
  font-size: $h3-font-size;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    font-size: 1.24em;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    font-size: 1.22em;
  }
}

h5,
.h5 {
  font-size: $h5-font-size;
  font-weight: 500;
}

h6,
.h6 {
  font-size: $h6-font-size;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-title;
  color: $dark;
  line-height: 1.3;

  a {
    color: $dark;
  }
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

b,
strong,
.strong {
  font-weight: 600;
  color: $gray-900;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  // height     : 100vh;
  // padding-top: 75px;
  margin-top: 60px;

  &.show {
    opacity: 1;
  }
}

.content-body {
  margin-top: 18px 6px 45px 65px;
}

.bg-gray {
  background-color: #fafafb;
}

// Container
.container-sm {
  max-width: 990px !important;
}

.container-fluid {
  padding-left: 30px;
  padding-right: 30px;

  @include media-breakpoint-up(xxl) {
    max-width: 1720px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.rounded {
  border-radius: $border-radius-base !important;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: $primary;
}

p {
  line-height: 1.3;
}
