.bi,
.ci,
.fab,
.fa,
.fas {
  vertical-align: middle;

  &.icona-padded {
    padding: 0.5rem;
  }

  &.icona-md {
    font-size: 2rem;
  }

  &.icona-xs {
    font-size: 1rem;

    &.icona-padded {
      padding: 0.25rem;
    }
  }

  &.icona-sm {
    font-size: 1.5rem;

    &.icona-padded {
      padding: 0.374rem;
    }
  }

  &.icona-lg {
    font-size: 3rem;

    &.icona-padded {
      padding: 0.75rem;
    }
  }

  &.icona-xl {
    font-size: 4rem;

    &.icona-padded {
      padding: 1rem;
    }
  }
}

// Force color when icona-color class is explicitly used
@each $color, $value in $theme-colors {
  .icona-#{$color} {
    color: $value !important;
  }
}

@import "./icon-moon";
