@font-face {
  font-family: icomoon;
  src: url("../../assets/fonts/icomoon.eot?7vimyr");
  src: url("../../assets/fonts/icomoon.eot?7vimyr#iefix") format("embedded-opentype"),
    url("../../assets/fonts/icomoon.ttf?7vimyr") format("truetype"),
    url("../../assets/fonts/icomoon.woff?7vimyr") format("woff"),
    url("../../assets/fonts/icomoon.svg?7vimyr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icona-menu-campagna-screening::before {
  content: "\e900";
  font-size: 1.5rem;
}

.icon-icona-menu-contatti-screening::before {
  content: "\e901";
  font-size: 1.5rem;
}

.icon-icona-menu-appuntamenti-screening::before {
  content: "\e902";
  font-size: 1.5rem;
}

.icon-icona-mamma-baby::before {
  content: "\e903";
  font-size: 1.5rem;
}

.icon-icona-mamma-mother::before {
  content: "\e904";
  font-size: 1.5rem;
}

.icon-punti-vaccinali::before {
  content: "\e905";
}

.icon-corredo-vaccinale::before {
  content: "\e906";
}
