@use "sass:color";

form {
  .form-group {
    margin: 1.5em 0;

    label {
      font-weight: 501;
    }

    input {
      border: none;
      border-bottom: 1px $black solid;
      border-radius: 0;
      color: color.adjust($gray-600, $lightness: -10%);

      &.error-box {
        border: 1px #e62615 solid;
      }
    }

    textarea {
      &.error-box {
        border: 1px #e62615 solid;
      }
    }

    select {
      border: none;
      border-bottom: 1px $black solid;
      border-radius: 0;
      color: color.adjust($gray-600, $lightness: -10%);
      background-image: url("../../assets/chevron-down-orange.svg");

      &.error-box {
        border: 1px #e62615 solid;
      }
    }

    .date-control {
      border: none;
      border-bottom: 1px $black solid;
      border-radius: 0;
      color: color.adjust($gray-600, $lightness: -10%);
      background-image: url("../../assets/calendar.svg");
      width: 100%;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      display: block;
      padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      background-color: $white;
      background-size: 16px 12px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none;
    }
  }

  .filter-tipo-documento {
    .badge {
      white-space: normal;
    }
  }
}

ngb-datepicker {
  border-radius: 5%;
  box-shadow: 0.1rem 0.25rem 0.5rem 0 rgb(0 0 0 / 20%);
  width: fit-content;
  margin: auto;

  .ngb-dp-header {
    background-color: transparent;

    .ngb-dp-navigation-select {
      select {
        border: none;
        color: $black;
        background-color: transparent;
      }
    }

    .ngb-dp-arrow {
      order: 1;

      &.right {
        order: 2;
      }

      .btn {
        color: $black;
      }
    }
  }

  .ngb-dp-day {
    .btn-light {
      text-align: center;
      height: 2rem;
      width: 2rem;
      font-weight: 501;
      color: $gray-700;

      &:hover {
        background-color: $secondary;
        border-radius: 50%;
        color: white;
      }

      &[ng-reflect-selected="true"] {
        background-color: $secondary !important;
        border-radius: 50%;
        color: white;
      }
    }
  }

  .ngb-dp-week {
    margin: 0.4rem 0;
  }

  .ngb-dp-weekdays {
    border-bottom: 0 !important;

    .ngb-dp-weekday {
      color: $black;
    }
  }
}

.no-result {
  border: 2px $secondary solid;
  border-radius: 20px;
  margin: 1em 0;
  padding: 1em;

  @include media-breakpoint-up(xl) {
    margin: 0 1em;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    color: $secondary;
  }

  hr {
    color: $secondary;
    height: 2px;
    margin-top: 0.5em;
  }
}

.filtro {
  margin-bottom: 0.2em;
  box-shadow: 0 0.2rem 0.4rem 0.2rem rgb(86 90 207 / 30%);
  border-radius: 1rem;

  @include media-breakpoint-up(xl) {
    border-bottom: 0;

    .dont-collapse-xl {
      display: block;
      height: auto !important;
      visibility: visible;
    }
  }

  .btn-collapse-filter {
    width: 100%;
    font-size: 18px;
    padding: 0.4em;
    justify-content: space-between;
    display: flex;
    font-weight: 400;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .title-filtro {
    h1 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }

    a {
      color: $secondary;
      font-size: 13px;

      &:hover {
        color: color.adjust($secondary, $lightness: -20%);
        cursor: pointer;
      }
    }
  }

  .btn-filtro {
    width: 100%;
    padding: 8px;
    font-size: 18px;
    font-weight: 400;
  }
}
