/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

//to ignore cli warning about deprecated mixin
$enable-deprecation-messages: false;
$ignore-warning: true;

// stylelint-disable-next-line scss/dollar-variable-default
$primary: #5159a3;
$secondary: #c85208 !default;
$success: #209f84 !default;
$info: #2754e6 !default;
$warning: #fe970e !default;
$danger: #f72b50 !default;
$light: #fff !default;
$bluelight: #f1f6ff !default;
$dark: #1f2278;
$gray: #666 !default;

// Body
$body-bg: #fff;
$body-color: #444;

// Typography
$font-family-base: "Montserrat", sans-serif;
$font-family-title: "Montserrat", sans-serif;
$headings-font-weight: 500;

$font-size-base: 1rem !default;
$font-weight-base: 500 !default;

// Heading Size
$h1-font-size: 2.2222em;
$h2-font-size: 1.8em;
$h3-font-size: 1.6em;
$h4-font-size: 1.4em;
$h5-font-size: 1.2em;
$h6-font-size: 1em;

$text-muted: #aeaed5;
$colors: () !default;

$heading-primary-size: 1.8rem;
$sub-heading-size: 1.6rem !default;
$grid-gutter-width: 30px;
$dropdown-lik-color: $body-color;
$border-color: #e1e1f0;
$headings-color: #14181d !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1199px,
  xxl: 1320px,
) !default;

$input-btn-focus-width: 0 !default;
$border-radius-base: 8px !default;
$border-radius-lg: 20px !default;
$shadow-color: rgb(160 159 202 / 15%);
$ft-color: #878e9c;

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
