.switch {
  border: 2px solid $secondary;
  display: flex;

  &.checked {
    background: transparent;

    small {
      background: $secondary;
    }
  }

  &.disabled {
    border: 2px solid $gray-800;

    &.checked {
      small {
        background: $gray-800;
        box-shadow: 0 0 0.4em 0.3em $gray-800;
      }
    }
  }
}
