section {
  position: relative;
  z-index: 1;
}

.text-white {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
}

.heading-bx {
  margin-bottom: 45px;

  .title-ext {
    font-weight: 700;
    display: inline-block;
    padding: 3px 15px;
    background-position: center;
    background-repeat: repeat;
    margin-bottom: 15px;

    &.text-primary {
      background-image: url("../../../assets/ext-blue.png");
    }

    &.text-secondary {
      background-image: url("../../../assets/ext-orange.png");
    }
  }

  h2.title {
    font-size: 45px;
    @include media-breakpoint-down(xxl) {
      font-size: 36px;
    }
    @include media-breakpoint-down(md) {
      font-size: 32px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 30px !important;
  }
  @include media-breakpoint-down(xl) {
    margin-bottom: 40px;
  }

  &.text-white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }

  p {
    padding-top: 5px;
    font-weight: 500;
    @include media-breakpoint-down(xxl) {
      font-size: 15px;
    }
    @include media-breakpoint-down(xl) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &.text-center p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  &.head-col {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: block;
    }

    .title {
      margin-bottom: 0;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }

    p {
      padding-left: 15px;
      border-left: 4px solid $primary;
      margin-left: 15px;
      margin-bottom: 0;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        border-left: 0;
        margin-left: 0;
      }
    }
  }
}
