/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
//@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// stylelint-disable scss/at-import-partial-extension
@import "~ngx-ui-switch/ui-switch.component";
@import "@fontsource/montserrat";
@import "@fontsource/montserrat/300.css";
@import "@fontsource/montserrat/500.css";
@import "@fontsource/montserrat/600.css";
@import "@fontsource/montserrat/300-italic.css";
@import "@fontsource/montserrat/400-italic.css";
@import "@fontsource/montserrat/latin-600.css";
// Theme customization
@import "theme/theme";

html,
body {
  height: 100%;
}
