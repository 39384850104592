.error-404 {
  margin: 3em 0;

  .inner-content {
    text-align: center;
    max-width: 550px;
    margin: auto;
  }

  .error-title {
    font-size: 180px;
    line-height: 180px;
    margin-bottom: 15px;
    font-weight: 500;

    p {
      width: 145px;
      height: 145px;
      display: inline-block;
      background-image: url("../../assets/error.gif");
      background-size: 100%;
      margin: 0 10px;
    }

    span {
      width: 18rem;
      height: 15rem;
      display: inline-block;
      background-image: url("../../assets/errore-lista-vuota.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 10px;

      &.server {
        display: inline-block;
        background-image: url("../../assets/errore-server.svg");
        background-size: contain;
        margin: 0 10px;
      }
    }
  }

  strong {
    color: $primary;
  }

  .error-text {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 26px;
    word-wrap: wrap;
  }

  @include media-breakpoint-down(md) {
    .error-title {
      font-size: 120px;
      line-height: 120px;

      span {
        width: 100px;
        height: 100px;
      }
    }

    .error-text {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 24px;
    }
  }
}
