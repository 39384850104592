@use "sass:math";

$grid-columns: 12;

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 math.percentage($size / $columns);
  max-width: math.percentage($size / $columns);
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  @for $i from 1 through $grid-columns {
    .col-xxl-#{$i} {
      flex: 0 0 math.percentage(calc($i / $grid-columns));
      max-width: math.percentage(calc($i / $grid-columns));
    }
  }
}
