.title-page {
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;

  h1 {
    font-size: 1.6rem;
    padding-left: 0.8rem;
  }

  .media {
    padding: 2rem;
    position: relative;
    text-align: center;
    z-index: 1;

    &::after,
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      @include transition;

      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    &::after {
      background-image: url("../../assets/hex-blue.png");
      background-size: 90%;
    }

    &::before {
      background-image: url("../../assets/hex-orange.png");
      background-size: 60%;
    }
  }
}
