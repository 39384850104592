@use "sass:color";

.nav-tabs {
  border: none;

  .nav-link {
    color: $gray-800;
    font-size: 1.1rem;
    font-weight: 401;
    transition: none;

    &:hover {
      border: none;
      border-bottom: 2px solid color.adjust($secondary, $lightness: 50%);
    }
  }

  .active {
    color: $secondary;
    border: none;
    border-bottom: 2px solid $secondary !important;
  }
}
