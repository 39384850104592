@use "sass:color";

a {
  cursor: pointer;
}

.dettaglio {
  h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

.caricatoIl {
  strong {
    font-weight: 500;
  }
}

.informazioni {
  span {
    font-weight: 300;
  }
}

.modal-header {
  display: flex !important;
  justify-content: space-between !important;
}

.autore {
  display: flex;
  flex-wrap: wrap;

  .box {
    display: flex;
    align-items: center;
    box-shadow: 0 0.2rem 0.4rem 0.2rem rgb(86 90 207 / 30%);
    padding: 1.5rem;
    border-radius: 1rem;
    height: 100%;

    .icona {
      padding: 1.2rem;
      margin-right: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      color: $white;
      overflow: visible;

      &.bg-orange-svg {
        background-image: url("../../assets/bg-orange.svg");
      }

      &.bg-border-orange-svg {
        background-image: url("../../assets/bg-border-orange.svg");
      }

      &.bg-violet-svg {
        background-image: url("../../assets/bg-violet.svg");
      }

      &.bg-border-violet-svg {
        background-image: url("../../assets/bg-border-violet.svg");
      }

      &.bg-white-svg {
        background-image: url("../../assets/bg-white.svg");
      }

      &.bg-bluette-svg {
        background-image: url("../../assets/bg-bluette.svg");
      }

      &.maschera-home-svg {
        background-image: url("../../assets/maschera.svg");
        background-size: cover;
        border-radius: 1rem 0 0 1rem;
        height: inherit;
      }

      &.maschera-blue-svg {
        background-image: url("../../assets/machera-blu.svg");
        background-size: cover;
        border-radius: 1rem 0 0 1rem;
        height: inherit;
      }

      &.bg-orange-opaco-svg {
        background-image: url("../../assets/bg-orange-opaco.svg");
      }
    }

    .informazioni {
      display: flex;
      flex-direction: column;
      line-height: 1rem;
    }
  }
}

.pdf {
  width: 100%;
  display: flex;
  justify-content: center;

  object {
    width: 100%;
    max-width: 850px;
    height: auto;
    max-height: calc(100vh - 200px);
    aspect-ratio: 2/3;
  }
}
